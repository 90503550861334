import { HelpVideo, MetricMaster, Trust, MetricCategories, HelpText, UserSettings } from './types';
import axios from 'axios';
import {TrustSettings} from './types';

// return the user data from the session storage
export const getUser = () => {
  const userStr = sessionStorage.getItem('user');
  if (userStr) return JSON.parse(userStr);
  else return null;
};

export const getCurrentContextUser = () => {
  const userStr = sessionStorage.getItem('contextUser');
  if (userStr) return JSON.parse(userStr);
  else return null;
};

// return the token from the session storage
export const getToken = () => {
  return sessionStorage.getItem('token') || null;
};

// remove the token and user from the session storage
export const removeUserSession = () => {
  sessionStorage.removeItem('token');
  sessionStorage.removeItem('user');
};

// set the token and user from the session storage
export const setUserSession = (token: string, user: any) => {
  sessionStorage.setItem('token', token);
  sessionStorage.setItem('user', JSON.stringify(user));
};

export const setUserSettings = (userSettings: UserSettings) => {
    sessionStorage.setItem('userSettings', JSON.stringify(userSettings));
};

export const getUserSettings = () => {
    let userSettings: UserSettings =
    {
        defaultVerbosityLevel: '',
        canChooseAllLocation: false,
        canCreateTrustProfiles: false
    }
    if (sessionStorage.getItem('userSettings') != null) {
        userSettings = JSON.parse(sessionStorage.getItem('userSettings')!)
    }
    return userSettings;
};

export const getTrust = () => {
  let trust: Trust = {
    trustName: sessionStorage.getItem('trustName')!,
    trustId: Number(sessionStorage.getItem('trustId')!),
  };
  return trust;
};

export const setTrust = (trust: Trust) => {
  sessionStorage.setItem('trustId', trust.trustId.toString());
  sessionStorage.setItem('trustName', trust.trustName);
};

export async function GetTrustSettings(trustId: number): Promise<TrustSettings | undefined> {
  let trustSettings: TrustSettings | undefined = undefined;

  await axios
    .get(process.env.REACT_APP_APIURL + 'trustsettings/' + trustId, {
      headers: {
        Authorization: 'Bearer ' + getToken(),
      },
    })
    .then((response) => {
      trustSettings = response.data;
    });
  return trustSettings;
}

export async function GetHelp(): Promise<HelpText[] | undefined> {
  let helpTexts: HelpText[] | undefined = undefined;

  await axios
    .get(process.env.REACT_APP_APIURL + 'help/', {})
    .then((response) => {
      helpTexts = response.data;
    })
    .catch((error: any) => {
      console.log(error);
    });
  return helpTexts;
}

export async function GetMetricCategories(): Promise<MetricCategories[] | undefined> {
  let metricCategories: MetricCategories[] | undefined = undefined;

  await axios
    .get(process.env.REACT_APP_APIURL + 'MetricCategories/', {})
    .then((response) => {
      metricCategories = response.data;
    })
    .catch((error: any) => {
      console.log(error);
    });
  return metricCategories;
}

export async function GetMetricMaster(trustName: string): Promise<MetricMaster[] | undefined> {
  let metricMaster: MetricMaster[] | undefined = undefined;

  await axios
    .get(process.env.REACT_APP_APIURL + 'metricmasters/' + trustName, {
      headers: {
        Authorization: 'Bearer ' + getToken(),
      },
    })
    .then((response) => {
      metricMaster = response.data;
    })
    .catch((error: any) => {
      console.log(error);
    });
  return metricMaster;
}

export async function GetConsoleVideos(): Promise<HelpVideo[] | undefined> {
   
    let helpVids: HelpVideo[] | undefined = undefined;

    await axios.get(process.env.REACT_APP_APIURL + 'consolevideos/', {}
        ).then(response => {
            helpVids = response.data
        }).catch((error: any) => {
            console.log(error)
        });
    return helpVids;
}
