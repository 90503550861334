import React, {useState} from 'react';
import axios from 'axios';
import { GetConsoleVideos, setUserSession, setTrust, GetTrustSettings, getTrust, setUserSettings } from './Utils/Common';
import PublicRoute from './Utils/PublicRoute';
import { NavLink } from 'react-router-dom';
import { HelpVideo } from './Utils/types';
import './login.css';
import ForgotPassword from './components/ForgotPassword';
import Register from './components/Register';
import logo from './media/OB_logo.png';
import jwt_decode from 'jwt-decode';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import { Button as TelButton } from '@progress/kendo-react-buttons';
interface LoginProps {
    history: any[];
}

interface JWPToken {
    TrustAccess: string
}
export default function Login(props: LoginProps ) {
    const [loading, setLoading] = useState(false);
    const username = useFormInput('');
    const password = useFormInput('');
    const [error, setError] = useState<string | undefined>(undefined);
    const [anchorHelpMenuEl, setAnchorHelpMenuEl] = React.useState < null | HTMLElement > (null);
    const [helpMenuItems, setHelpMenuItems] = React.useState < HelpVideo[] | undefined > ();
    const helpMenuOpen = Boolean(anchorHelpMenuEl);

  // handle button click of login form
  const handleLogin = async () => {
    setError('');
    setLoading(true);

    await axios
      .post((process.env.REACT_APP_APIURL || '') + 'auth/signin', {email: username.value, password: password.value})
      .then(async (response) => {
        setLoading(false);
        setUserSession(response.data, username.value);
        let decoded: JWPToken = jwt_decode(response.data);
        const valuesArray = JSON.parse(decoded.TrustAccess);
        if (valuesArray.length === 1) {
          let trust = {
            trustId: valuesArray[0].TrustId,
            trustName: valuesArray[0].TrustName,
          };
            setTrust(trust);
            let UserSettings = {
                defaultVerbosityLevel: valuesArray[0].VerbosityLevel,
                canChooseAllLocation: valuesArray[0].CanChooseAllLocation,
                canCreateTrustProfiles: valuesArray[0].CanCreateTrustProfiles
            }
            setUserSettings(UserSettings)
          let trustSettings = await GetTrustSettings(getTrust().trustId);
          if (trustSettings!.softwareProvider === 'Allocate') {
            props.history.push('/wardguardian');
          } else {
            props.history.push('/DataQuality');
          }
        } else if (valuesArray.length > 1) {
          props.history.push('/trustpicker');
        }
      })
      .catch((error) => {
        setLoading(false);
        if (error.response == null) setError('Something went wrong. Please try again later.');
        else if (error.response.status === 404) setError(error.response.data);
        else setError('Something went wrong. Please try again later.');
      });
  };

    const loadHelp = async () => {

        let helpVideo: HelpVideo[] | undefined = await GetConsoleVideos()!

        if (helpVideo !== undefined) {
            let hv = helpVideo.filter((items: { loginScreen: boolean }) => items.loginScreen === true)
            setHelpMenuItems(hv)

        }
    }

    const handleHelpMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorHelpMenuEl(event.currentTarget);
    };

    const handleHelpMenuClose = () => {
        setAnchorHelpMenuEl(null);
    };

    React.useEffect(() => {
        setLoading(true);
        async function loadContent() {
            await loadHelp();
        }

        loadContent()
        setLoading(false);
    }, []);

  return (
    <div className="loginbackground">
      <div className="loginbody">
        <div className="oceansbluecentrallogo">
          <span>
            <span>Ward Guardian Compliance Console</span>
          </span>
        </div>
        <div className="login">
          <div className="login-screen">
            <div className="app-title">
              <h1 className="h1">Log in</h1>
              <img className="oblogologin" src={logo} alt="Oceans Blue Logo" />
            </div>

            <div className="login-form">
              <div className="control-group">
                <input
                  type="text"
                  {...username}
                  autoComplete="new-password"
                  className="login-field"
                  placeholder="Username"
                  id="login-name"></input>
                <label className="login-field-icon fui-user" htmlFor="login-name"></label>
              </div>
              <div className="control-group">
                <input
                  type="password"
                  {...password}
                  autoComplete="new-password"
                  className="login-field"
                  placeholder="Password"
                  id="login-pass"></input>
                <label className="login-field-icon fui-lock" htmlFor="login-pass"></label>
              </div>
              {error && (
                <>
                  <span style={{color: 'red'}}>{error}</span>
                  <br />
                </>
              )}
              <br />
              <div className="control-group">
                              <NavLink  to="/Register">
                  Click here to register
                </NavLink>
                <PublicRoute path="/Register" component={Register} />
                          </div>

                          <div className="button-group">
                              <Grid container spacing={3}>
                                  <Grid item xs={6}>

                                      <TelButton
                                          className="buttons-container-button"
                                          aria-label="help"
                                          aria-controls="menu-help"
                                          aria-haspopup="true"
                                          onClick={handleHelpMenu}
                                          title="Help"
                                          iconClass="fa-solid fa-question">
                                          <span style={{ fontSize: '13px', paddingRight: '5px' }}>Help</span>

                                      </TelButton>
                                     
                                  <Menu id="menu-help"
                                      anchorEl={anchorHelpMenuEl}
                                      onBlur={handleHelpMenuClose}
                                      anchorOrigin={{
                                          vertical: 'top',
                                          horizontal: 'right',
                                      }}
                                      keepMounted
                                      transformOrigin={{
                                          vertical: 'top',
                                          horizontal: 'right',
                                      }}
                                      open={helpMenuOpen}
                                      onClose={handleHelpMenuClose}>
                                      {
                                          helpMenuItems != undefined && helpMenuItems

                                              .sort((a, b) => a.sortOrder - b.sortOrder)
                                              .map((video: { menuName: string, videoURL: string }) =>
                                                  <MenuItem>
                                                      <a href={video.videoURL} target="_blank">{video.menuName}</a>
                                                  </MenuItem>
                                              )
                                      }
                                      <Divider />
                                      <MenuItem>
                                          <NavLink className="linknexttobutton" to="/ForgotPassword">
                                              Forgotten password?
                                          </NavLink>
                                          <PublicRoute path="/ForgotPassword" component={ForgotPassword} />
                                      </MenuItem>
                                  </Menu>
                              </Grid>
                              <Grid item xs={6}>
                                   <div style={{ padding: '8px'}}>
                                      <input
                                          className="btnright"
                                          id="submit"
                                          type="button"
                                          value={loading ? 'Loading...' : 'Login'}
                                          onClick={handleLogin}
                                          disabled={loading}
                                      />
                                  </div>
                              </Grid>
                          </Grid>
              </div>
            </div>
          </div>
        </div>
    </div>
   
      <span className="pintobottom">Application Version {process.env.REACT_APP_APPVERSION}</span>
    </div>
  );
}

const useFormInput = (initialValue:string) => {
  const [value, setValue] = useState(initialValue);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setValue(e.target.value);
  };
  return {
    value,
    onChange: handleChange,
  };
};
