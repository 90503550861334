import * as React from 'react';
import { AppBar, AppBarSection, AppBarSpacer} from '@progress/kendo-react-layout';
import { GetConsoleVideos, getToken, getTrust, GetTrustSettings, setTrust, setUserSettings, getUser} from '../Utils/Common';
import IconButton from '@material-ui/core/IconButton';
import AccountCircle from '@material-ui/icons/AccountCircle';
import Help from '@material-ui/icons/Help';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import {DropDownList, DropDownListChangeEvent} from '@progress/kendo-react-dropdowns';
import jwt_decode from 'jwt-decode';
import {HelpVideo, Trust} from '../Utils/types';
import Divider from '@material-ui/core/Divider';

import {SidebarContext} from './context/SidebarContextProvider';

interface NavMenuProps {
  history: any[];
  selectedTab: number;
  disabledTabs: boolean[];
}

interface JwtPayload {
  TrustAccess: string;
}

export default function NavMenu(props: NavMenuProps) {
  const {handleSetHeader, handleSideBar, handleProfile} = React.useContext(SidebarContext);

  const token = getToken();
    const [dropdownValues, setDropdownValues] = React.useState();
    const [helpMenuItems, setHelpMenuItems] = React.useState < HelpVideo[] | undefined>();
    const [dropdownSelectedValue, setDropdownSelectedValue] = React.useState();
    const [anchorAccountMenuEl, setAnchorAccountMenuEl] = React.useState<null | HTMLElement>(null);
    const [anchorHelpMenuEl, setAnchorHelpMenuEl] = React.useState<null | HTMLElement>(null);
    const [supportEmail, setSupportEmail] = React.useState<string>();
    const accountMenuOpen = Boolean(anchorAccountMenuEl);
    const helpMenuOpen = Boolean(anchorHelpMenuEl);
    const [numberOfTrusts, setNumberOfTrusts] = React.useState(1);
    const [loading, setLoading] = React.useState(false);

  const handleAccountMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorAccountMenuEl(event.currentTarget);
  };

  const handleAccountMenuClose = () => {
    setAnchorAccountMenuEl(null);
    };

    const handleHelpMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorHelpMenuEl(event.currentTarget);
    };

    const handleHelpMenuClose = () => {
        setAnchorHelpMenuEl(null);
    };

  const menuGotoUrl = (siteUrl: any) => (ev: any) => {
    props.history.push(siteUrl);
  };

  const loadData = async () => {
    let trustSettings = await GetTrustSettings(getTrust().trustId);
    setSupportEmail(trustSettings!.supportEmailAddress != undefined ? 'mailto:' + trustSettings!.supportEmailAddress : 'mailto:support@oceansblue.co.uk',
    );
    };

   

  const handleChange = (event: DropDownListChangeEvent) => {
    let trust: Trust = {
      trustId: event.target.value.TrustId,
      trustName: event.target.value.TrustName,
    };
    setTrust(trust);
      let UserSettings = {
          defaultVerbosityLevel: event.target.value.VerbosityLevel,
          canChooseAllLocation: event.target.value.CanChooseAllLocation,
          canCreateTrustProfiles: event.target.value.CanCreateTrustProfiles
      }
      setUserSettings(UserSettings)
    setDropdownSelectedValue(event.target.value);
    window.location.reload();
  };

    const loadHelp = async () => {

        let helpVideo: HelpVideo[] | undefined = await GetConsoleVideos()!

        if (helpVideo !== undefined) {
            let hv = helpVideo.filter((items: { mainMenu: boolean }) => items.mainMenu === true)
            setHelpMenuItems(hv)
            
        }
    }


  React.useEffect(() => {
        setLoading(true);
      async function loadContent() {
          await loadHelp();
      }

      loadContent()

        const token = getToken();
        const valuesArray = JSON.parse(jwt_decode<JwtPayload>(token!)!.TrustAccess);
        setNumberOfTrusts(valuesArray!.length);
        setDropdownValues(valuesArray);
        const trustId = getTrust()!.trustId;
        const selectedValue = valuesArray.find((trust: any) => trust.TrustId === trustId);
        if (selectedValue) {
          setDropdownSelectedValue(selectedValue);
        }
      loadData();
      setLoading(false);
  }, []);

  return (
    <AppBar>
      <AppBarSection className="title">
        <span className="oceansbluefont">
          <span>Ward Guardian Compliance Console</span>
          {/* <span style={italicText}>Ward Guardian Compliance Console</span> */}
        </span>
      </AppBarSection>

      <AppBarSpacer style={{width: 500}} />

      <AppBarSpacer />

      <AppBarSection>
        {!{loading}.loading ? (
          <div>
            {numberOfTrusts === 1 ? (
              <span className="trustpadding"> {getTrust()!.trustName}</span>
            ) : (
              <div style={{paddingRight: '10px', paddingBottom: '5px'}}>
                <DropDownList
                  data={dropdownValues}
                  textField="TrustName"
                  onChange={handleChange}
                  defaultValue={dropdownSelectedValue}
                  style={{width: '140px'}}
                />
              </div>
            )}
          </div>
        ) : (
          <span></span>
        )}
        <span className="k-appbar-separator" />
        <span className="trustpadding">{`Logged in as ${getUser()}`}</span>
        <span className="k-appbar-separator" />
            
              <div>
                  <IconButton
                      aria-label="help"
                      aria-controls="menu-help"
                      aria-haspopup="true"
                      onClick={handleHelpMenu}
                      color="inherit"><span style={{ fontSize: '13px', paddingRight: '5px' } }>Help</span>
                      
                      <Help />
                  </IconButton>
                  <Menu id="menu-help"
                      anchorEl={anchorHelpMenuEl}
                      onBlur={handleHelpMenuClose}
                      anchorOrigin={{
                          vertical: 'top',
                          horizontal: 'right',
                      }}
                      keepMounted
                      transformOrigin={{
                          vertical: 'top',
                          horizontal: 'right',
                      }}
                      open={helpMenuOpen}
                      onClose={handleHelpMenuClose}>
                      {
                          helpMenuItems != undefined && helpMenuItems
                              
                              .sort((a, b) => a.sortOrder - b.sortOrder)
                              .map((video: { menuName: string, videoURL: string }) =>
                                  <MenuItem>
                                      <a href={video.videoURL} target="_blank">{video.menuName}</a>
                                   </MenuItem>
                              )
                      }
                      <Divider />
                      <MenuItem>
                          <a href={supportEmail}>Get Support</a>
                      </MenuItem>
                  </Menu>
              </div>   
              <span className="k-appbar-separator" />
        <div>
          <IconButton
            aria-label="account of current user"
            aria-controls="menu-appbar"
            aria-haspopup="true"
            onClick={handleAccountMenu}
            color="inherit">
            <AccountCircle />
          </IconButton>
          <Menu
            id="menu-appbar"
            anchorEl={anchorAccountMenuEl}
            onBlur={handleAccountMenuClose}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            keepMounted
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            open={accountMenuOpen}
            onClose={handleAccountMenuClose}>
            <MenuItem onClick={menuGotoUrl('/changepassword')}>Change Password</MenuItem>
            <MenuItem
              onClick={() => {
                handleSetHeader('Columns');
                handleSideBar(true);
                handleProfile(undefined);
              }}>
              Edit Column Profiles
            </MenuItem>
            <MenuItem
              onClick={() => {
                handleSetHeader('Units');
                handleSideBar(true);
                handleProfile(undefined);
              }}>
              Edit Unit Profiles
            </MenuItem>
            <MenuItem
              onClick={() => {
                handleSetHeader('Linked');
                handleSideBar(true);
                handleProfile(undefined);
              }}>
              Edit Linked Profiles
            </MenuItem>
            <MenuItem
              onClick={() => {
                handleSetHeader('Subscriptions');
                handleSideBar(true);
                handleProfile(undefined);
              }}>
              Edit Subscriptions
            </MenuItem>
            {getUser().includes('@oceansblue.co.uk') && <MenuItem onClick={() => window.open('UserActivity')}>View User Activity</MenuItem>}
            <MenuItem onClick={menuGotoUrl('/logout')}>Logout</MenuItem>
          </Menu>
        </div>
      </AppBarSection>
    </AppBar>
  );
}
